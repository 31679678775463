<template>
  <MainLayout>
    <b-overlay
      :show="loading"
      rounded
      spinner-type="grow"
      spinner-variant="primary"
      style="min-height: 90vh"
    >
      <div v-if="item">
        <h1>
          <i :class="['bx', resource.icon]"></i>
          {{ item.$meta.title }}
        </h1>

        <action-bar v-bind="{item,resource}" @action="action" />

        <div class="row row-cols-1 row-cols-md-2 mt-4">
          <div class="col mb-4">
            <b-card>
              <template #header> 
                <i class='bx bxs-cart' />
                {{$t('location_groups.label')}}
              </template>
              <dl>
                <value label="common.name" icon="bx-purchase-tag" :value="item.name"/>
              </dl>
            </b-card>
          </div>
          <div class="col mb-4">
            <b-card>
              <template #header>
                <i class="bx bxs-map"></i>
                {{ $t("locations.title") }}
              </template>
              <dl>
                <value label="locations.title" icon="bx bxs-map" :value="item.$locations">
                    <div v-for="location in item.$locations" :key="location.id">
                      <router-link :to="{name: `locations-show`,params:{id: location.id}}">
                        {{location.name}}
                      </router-link>
                    </div>
                </value>
              </dl>
            </b-card>
          </div>
        </div>
      </div>
      <debug v-bind="{item}" />
    </b-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/Main'
import ItemShow from '@/components/ItemShow'
import Value from "@/components/Value";
import ActionBar from '@/components/ActionBar.vue'

export default {
  extends: ItemShow,
  components: { 
    MainLayout,
    Value,
    ActionBar
  }
};
</script>